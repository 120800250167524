import { useRef } from "react";
import { useRouter } from "next/router";
/**
 * Create ref to the functional component (It will not re-run on each render)
 * @param ViewModelConstructor
 * @param props context
 * @return ViewModelConstructor
 */ export function useViewModel(props, ViewModelConstructor) {
    const router = useRouter();
    let vmRef = useRef(ViewModelConstructor({
        ...props,
        router
    }));
    return vmRef.current;
}
